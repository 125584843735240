<template>
  <div class="flex flex-col h-full">
    <div class="flex-none mb-4">
      <core-modal-header>
        Declutter:
        <span v-if="context">
          <span v-if="context.name" class="font-bold">{{context.name}}</span>
          <span v-else class="italic text-gray-400">Unnamed</span>
        </span>
        <span v-else class="font-bold">Gallery</span>
      </core-modal-header>

      <div class="grid grid-cols-2 sm:flex items-center mt-2 px-3 sm:px-6 gap-3 sm:gap-6">
        <div class="flex justify-start">
          <core-date-range-picker
            v-model="dateRange"
            class="flex-0 w-auto"
          />
        </div>


        <div class="flex-1 flex items-center gap-2 order-3 sm:order-2 col-span-2">
          <u-range v-model="timeInterval" :min="DECLUTTER_MIN_TIME_INTERVAL" :max="DECLUTTER_MAX_TIME_INTERVAL" :step="DECLUTTER_TIME_INTERVAL_STEP" class="flex-1" />
          <div class="flex-0 flex items-center text-sm sm:text-base">
            <div>taken within <span class="font-bold"> {{timeInterval}} </span> seconds</div>
          </div>
        </div>

        <div class="flex-0 flex order-2 sm:order-3 justify-end">
          <list-grid-size :id="gridSizeId" :default-size="FILE_GRID_SIZES.lg" />
        </div>
      </div>
    </div>


    <div class="flex-center w-full flex-1 px-3 sm:px-6">
      <file-list-grid
        class="w-full"
        key="list-grid"
        :files="filesWithGroupData"
        :files-store-id="filesStoreId"
        group-files
        disable-selection
        :disable-approach-bottom="status === 'pending' || isGettingMore || !hasMore"
        :get-group-files="getGroupFiles"
        :grid-size-id="gridSizeId"
        @approach-bottom="getMore"
        @list-item-click="onItemClick"
      >
        <template #header>
          <transition-group name="f-slide-fade">
            <core-contextual-loading-box v-if="isLoading" key="loading" loading-text="Looking for clutter..." />
            <core-contextual-error-box v-else-if="error" key="error" :action="refresh" />
          </transition-group>
        </template>

        <template #under-header>
          <core-empty-state
            v-if="isEmpty"
            heading="Nice work! This Space is Clutter-Free 🎉"
            description="You did it! But why stop now? Continue de-cluttering by adjusting your time interval and date range to refine your results even further."
            :image="ImgDeclutterEmpty"
          />
        </template>

        <template #list-item-optional-items="{file}">
          <core-list-item-delete class="absolute top-1.5 right-1.5 z-50" @delete="deleteFile({file})" />
        </template>
      </file-list-grid>
    </div>
  </div>
</template>

<script setup>
  import debounce from 'lodash.debounce';
  import {storeToRefs} from 'pinia';
  import {makeFilesStore} from '~/stores/files.js';
  import {useLazyAsyncData} from '#app';
  import {getFilesWithDeclutterGroupData} from '~/utils/file.js';
  import ListGridSize from '~/components/file/list-grid-size.vue';
  import ImgDeclutterEmpty from '~/assets/images/declutter-empty.png';

  const emit = defineEmits(['files-deleted']);
  const props = defineProps({
    context: Object,
    contextType: String,
    modal: Object
  });

  const filesStoreId = DECLUTTER_FILES_STORE_ID;
  const gridSizeId = 'DECLUTTER';
  const filesStore = makeFilesStore(filesStoreId)();
  const selection = useSelection();
  const dayjs = useDayjs();
  const fileActions = useFileActions();
  const listEl = ref();
  const {files, hasMore, isLoadingFiles} = storeToRefs(filesStore);
  const timeInterval = ref(DECLUTTER_INITIAL_TIME_INTERVAL);
  const timeIntervalForFiles = ref(DECLUTTER_INITIAL_TIME_INTERVAL);
  const updateTimeIntervalForFiles = debounce(() => (timeIntervalForFiles.value = timeInterval.value), 500);
  const dateRange = ref({});

  watch(timeInterval, () => {
    selection.clear();
    updateTimeIntervalForFiles();
  });

  const {refresh, error, status} = await useLazyAsyncData(
    'files-declutter',
    () => filesStore.getFilesForDeclutter({context: props.context, contextType: props.contextType, dateRange: dateRange.value}),
    {
      watch: [dateRange]
    }
  );

  //todo: potentially clean up group computation to reduce the number of loops through the loaded files
  const filesWithGroupData = computed(() => getFilesWithDeclutterGroupData({
    files: files.value,
    interval: timeIntervalForFiles.value
  }));

  const groups = computed(() => filesWithGroupData.value?.reduce((theGroups, file) => {
    const lastGroup = theGroups[theGroups.length - 1]
    const currentGroup = lastGroup?.id === file.group.id
      ? lastGroup
      : (() => {
          const newGroup = Object.assign({}, file.group, {files: []});
          theGroups.push(newGroup);

          return newGroup;
        })();

    currentGroup.files.push(file);

    return theGroups;
  }, []));

  const isLoading = computed(() => isLoadingFiles.value || (!filesWithGroupData.value?.length && hasMore.value));
  const isEmpty = computed(() => !filesWithGroupData.value?.length && !isLoadingFiles.value && !hasMore.value && status.value === 'success');
  const getMoreError = ref(null);
  const isGettingMore = ref(false);

  async function getMore() {
    if (!hasMore.value || isGettingMore.value) {
      return;
    }

    isGettingMore.value = true;

    try {
      await filesStore.getFilesForDeclutter({nextPage: true});
    } catch (e) {
      getMoreError.value = e; //todo: what do we do?
    } finally {
      isGettingMore.value = false;
    }
  }

  //note: this method and the following watchers handle a special case where we have gotten new files but none of them can be added to the current declutter state - keep getting more files until we meet the condition for the core-list to get in the DOM. After that the regular approach-bottom checking mechanism can take over
  function checkForMoreFiles() {
    if (status.value === 'success' && !filesWithGroupData.value?.length && hasMore.value) {
      getMore();
    }
  }

  watch(filesWithGroupData, checkForMoreFiles);
  watch(status, checkForMoreFiles);
  watch(isGettingMore, checkForMoreFiles);

  const getGroupFiles = async ({groupId}) => {
    const groupFiles = computed(() => filesWithGroupData.value.filter(file => file.group.id === groupId));

    while(hasMore.value && (filesWithGroupData.value.findLastIndex(file => file.group.id === groupId) === filesWithGroupData.value.length - 1)
      ) {

      await getMore();
    }

    return groupFiles.value;
  };

  function onItemClick(item) {
    const currentGroupIndex = ref(groups.value.findIndex(g => g.id === item.group.id));
    const currentGroup = ref(groups.value[currentGroupIndex.value]);
    const hasPrevGroup = computed(() => currentGroupIndex.value > 0);
    const hasNextGroup = computed(() => (
      currentGroup.value.files.length === 1
        ? currentGroupIndex.value < groups.value.length //note: this is to account for the case where the current group is no longer in the groups array
        : currentGroupIndex.value < (groups.value.length - 1)
    ));

    watch(currentGroupIndex, newVal => {
      currentGroup.value = groups.value[newVal];
    });

    const modal = useFModal({
      id: 'declutter-compare-modal',
      clickToClose: false,
      showClose: false,
      escToClose: false,
      size: 'fullscreen',
      component: (process.client ? defineAsyncComponent(() => import('~/components/file/declutter-compare-modal.vue')) : {}),
      props: {
        group: currentGroup,
        hasPrevGroup,
        hasNextGroup,
        context: props.context,
        contextType: props.contextType,
        focusIndex: currentGroup.value.files.findIndex(f => f.id === item.id)
      },
      eventHandlers: {
        filesDeleted({deletedFiles}) {
          emit('files-deleted');

          currentGroup.value.files = currentGroup.value.files.filter(f => f.id !== deletedFiles[0].id);
          //todo: ignoring updates to fileCount for now - see if that can be cleaned up

          if (!currentGroup.value.files.length) {
            //handle cases when the last of a group files is deleted
            if (groups.value[currentGroupIndex.value]) {
              //we have a next group we can go to and we don't have to update the index
              currentGroup.value = groups.value[currentGroupIndex.value];
            } else if (currentGroupIndex.value) {
              //we don't have a next group to go to, but we cna go to the previous one
              currentGroupIndex.value--;
              } else {
              //we have no groups remaining - exit
              modal.close();
            }
          }
        },
        prevGroup() {
          currentGroupIndex.value--;
        },
        nextGroup() {
          if (currentGroup.value.files.length === 1) {
            //we are on a suspended group - this group is no longer in the groups because it has only 1 file in it. Therefore we need to keep the currentGroupIndex the same but update the currentGroup
            currentGroup.value = groups.value[currentGroupIndex.value];
          } else {
            currentGroupIndex.value++;
          }
        }
      }
    });

    modal.open();
  }

  function deleteFile({file}) {
    fileActions.deleteFiles({
      files: [file],
      context: props.context,
      contextType: props.contextType,
      filesStoreId
    });
  }

</script>
